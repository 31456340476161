<template>
    <footer class="footer has-cards">
        <div class="container">
            <div class="row row-grid align-items-center my-md">
                <div class="col-lg-8 btn-wrapper">
                    <div class="row row-grid align-items-center">
                        <div class="col-lg-4 text-lg-center ">
                            <a target="_blank" rel="noopener" href="https://twitter.com/ulhpc"
                            class="btn btn-twitter btn-round" data-toggle="tooltip"
                            data-original-title="Follow us" style="font-size: 1.5rem">
                                <i class="fa fa-twitter"></i>
                            </a>
                            <a target="_blank" rel="noopener" href="https://github.com/ULHPC"
                            class="btn btn-github btn-round" data-toggle="tooltip"
                            data-original-title="Follow us on Github" style="font-size: 1.5rem">
                                <i class="fa fa-github"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 text-lg-center btn-wrapper">
                    <a href="https://wwwen.uni.lu/" target="_blank">
                        <img src="/assets/img/common/ul_logo.png" class="" width=180>
                    </a>
                </div>
            </div>
            <hr>
            <div class="row align-items-center justify-content-md-between">
                <div class="col-md-6">
                    <div class="copyright">
                        <router-link :to="{ name: 'homepage'}" class="nav-item">
                            <i class="fa fa-copyright" aria-hidden="true"></i> ULHPC - {{ year }}
                        </router-link>
                        <a href="mailto:hpc-team@uni.lu" class="nav-item" style="margin-left: 10px">HPC-Team@uni.lu</a>
                    </div>
                </div>
                <div class="col-md-6">
                    <ul class="nav nav-footer justify-content-end">
                        <router-link :to="{ name: 'about-support'}">
                            <li class="nav-item"><div class="nav-link">ULHPC Support</div></li>
                        </router-link>
                        <router-link :to="{ name: 'blog'}">
                            <li class="nav-item"><div class="nav-link">Blog</div></li>
                        </router-link>
                        <router-link :to="{ name: 'about-credits'}">
                            <li class="nav-item"><div class="nav-link">Credits</div></li>
                        </router-link>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
export default {
  name: 'app-footer',
  data() {
    return {
      year: new Date().getFullYear()
    }
  }
};
</script>
<style>
</style>
