<template>
  <div>
    <div class="row clearfix mt-5">
      <div class="col-md-12 column">
        <div class="card">
          <div class="card-header">
            <h3 class="card-title">
              {{ title }}
              <i class="pull-right"
                ><status-indicator :status="status" pulse></status-indicator
              ></i>
            </h3>
          </div>
        </div>

        <div class="row clearfix">
          <div class="col-md-12 column">
            <div class="list-group">
              <div
                v-for="(value, name) in services"
                class="list-group-item"
                :key="title + ':' + name"
              >
                <h4 class="list-group-item-heading">
                  <span v-if="value.cname" style="padding-right: 5px">{{ value.cname }}</span>
                  <span v-else style="padding-right: 5px">{{ name }}</span>
                  <i class="pull-right"
                    ><status-indicator :status="value.status"></status-indicator
                  ></i>
                </h4>
                <markdown-page v-if="value.details" :content="value.details" classes="" ></markdown-page>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "status-table",
  props: {
    title: {
      type: String,
      default: "Default Title",
    },
    status: {
      type: String,
      default: "",
    },
    services: {
      type: Object,
    },
  },
};
</script>

<style>
</style>
