<template>
  <div>
    <markdown-it-vue
      :class="classes"
      :content="content"
      :options="options"
    />
  </div>
</template>

<script>
export default {
  name: "markdown-page",
  props: {
    content: String,
    classes: {
      type: String,
      default: "container mt-4 text-justify"
    }
  },
  data() {
    return {
      options: {
        markdownIt: {
          html: true,
        },
        image: {
          hAlign: "center",
          viewer: false,
        },
      },
    };
  },
};
</script>

<style>
</style>
