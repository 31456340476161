<template>
  <div
    class="mt-5 mb-5"
    :class="[
      colRepartition && `col-lg-${colRepartition}`,
      { 'card-container': canFlip },
    ]"
  >
    <div
      class="bg-white rounded shadow-sm py-5 px-4"
      :class="[{ 'card-flip': canFlip }]"
    >
      <div :class="[{ 'flip-card-front': canFlip }]">
        <img
          :src="imgUrl"
          alt=""
          width="150"
          class="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
        />
        <h5 class="mb-0">{{ name }}</h5>
        <span v-if="role" class="small text-uppercase text-muted">{{
          role
        }}</span>
      </div>
      <div
        v-if="
          affiliations || office || phonenumber || emailaddress || websitelink
        "
        class="flip-card-back"
      >
        <p v-if="affiliations">{{ affiliations }}</p>
        <p v-if="office"><i class="fa fa-building"></i> {{ office }}</p>
        <p v-if="phonenumber"><i class="fa fa-phone"></i> {{ phonenumber }}</p>
        <p v-if="emailaddress">
          <i class="fa fa-at"></i>
          <a :href="'mailto:' + emailaddress">{{ emailaddress }}</a>
        </p>
        <p v-if="websitelink">
          <i class="fa fa-globe"></i>
          <a :href="websitelink" target="_blank">Uni.lu webpage</a>
        </p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "staff-card",
  props: {
    imgUrl: {
      type: String,
      default: "",
      description: "Image 150x150 of the person",
    },
    name: {
      type: String,
      default: "",
      description: "Name of the person",
    },
    role: {
      type: String,
      default: "",
      description: "Role of the person",
    },
    colRepartition: {
      type: String,
      default: "6",
      description: "See bootstrap grid page",
    },
    canFlip: {
      type: Boolean,
      default: true,
      description: "Enable the flip effect",
    },
    affiliations: {
      type: String,
      default: "",
      description: "Other affiliations of the person",
    },
    office: {
      type: String,
      default: "",
      description: "Office of the person",
    },
    phonenumber: {
      type: String,
      default: "",
      description: "Phone number",
    },
    emailaddress: {
      type: String,
      default: "",
      description: "Email address",
    },
    websitelink: {
      type: String,
      default: "",
      description: "Unilu website link",
    },
  },
};
</script>
<style>
.card-container {
  display: grid;
  perspective: 1500px;
}

.card-flip,
.card-container {
  display: grid;
  grid-template: 1fr / 1fr;
  grid-template-areas: "frontAndBack";
  transform-style: preserve-3d;
  transition: all 0.7s ease;
}

.card-flip div {
  backface-visibility: hidden;
  transform-style: preserve-3d;
}

.flip-card-back {
  grid-area: frontAndBack;
  transform: rotateY(-180deg);
}

.flip-card-front {
  grid-area: frontAndBack;
}

.card-container:hover .card-flip {
  transform: rotateY(180deg);
}
</style>
