<template>
    <div id="app">
        <router-view name="header"></router-view>
        <main>
            <router-view/>
        </main>
        <go-top :size="50" bg-color="#5c5c5c" v-if="!getTitle().includes('Homepage')"></go-top>
        <router-view name="footer"></router-view>
    </div>
</template>
<script>
import GoTop from '@inotom/vue-go-top';

export default {
  components: {
    GoTop
  },
  metaInfo: {
      title: 'Loading',
      titleTemplate: '%s | HPC @ Uni.lu'
  },
  methods: {
      getTitle: () => {
          return document.title;
      }
  }
};
</script>
