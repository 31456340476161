<template>
    <header class="header-global">
        <base-nav class="navbar-main" transparent type="" effect="light" expand>
            <router-link slot="brand" class="navbar-brand mr-lg-5" to="/">
                <img src="/assets/img/common/ulhpc-logo.svg" alt="logo" style="border: 5px solid rgba(255, 255, 255, 0); border-radius: 5px; background-color: rgba(255, 255, 255, .9)">
            </router-link>

            <div class="row" slot="content-header" slot-scope="{closeMenu}">
                <div class="col-6 collapse-brand">
                     <router-link to="/">
                        <img src="/assets/img/common/ulhpc-logo.svg">
                    </router-link>
                </div>
                <div class="col-6 collapse-close">
                    <close-button @click="closeMenu"></close-button>
                </div>
            </div>

            <!-- NAVIGATION BAR -->

            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">

                <!-- INFRASTRUCTURE MENU -->
                <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="ni ni-building d-lg-none"></i>
                        <span class="nav-link-inner--text text-sc header-menu-button">Infrastructure</span>
                    </a>
                    <div class="dropdown-menu-inner">
                        <router-link :to="{ name: 'infra-datacenter'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-building"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">University Datacenter</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'infra-supercomputers'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-server"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">ULHPC Supercomputers</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'infra-storage'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-hdd-o"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">High Performance Storage</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'infra-network'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-sitemap"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">High Performance Networks</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'infra-devops'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-cogs"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">DevOps Technologies</h5>
                            </div>
                        </router-link>
                         <router-link :to="{ name: 'infra-status'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-heartbeat"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">ULHPC Facility Status</h5>
                            </div>
                        </router-link>
                    </div>
                </base-dropdown>

                <!-- RESEARCH MENU -->
                <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="fa fa-binoculars d-lg-none"></i>
                        <span class="nav-link-inner--text text-sc header-menu-button">Research</span>
                    </a>
                    <div class="dropdown-menu-inner">
                        <router-link :to="{ name: 'research-pcog'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-search"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">PCOG <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'research-luxdem'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-search"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">LuxDEM <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'research-tcplu'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-search"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">TCP Unilu <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'research-lcsb'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-search"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">System BioMedicine <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'research-snt'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-search"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Security and Trust <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                    </div>
                </base-dropdown>

                <!-- PARTNERSHIP MENU -->
                <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="fa fa-exchange d-lg-none"></i>
                        <span class="nav-link-inner--text text-sc header-menu-button">Partnership</span>
                    </a>
                    <div class="dropdown-menu-inner">
                        <router-link :to="{ name: 'partner-castiel'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-spaceship"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">CASTIEL <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'partner-etp4hpc'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-spaceship"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">ETP4HPC <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'partner-eurocc'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-spaceship"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">EuroCC <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'partner-eurohpc'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-spaceship"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">EuroHPC <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'partner-grid5000'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-spaceship"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Grid5000 <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'partner-luxprovide'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-spaceship"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">LuxProvide <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'partner-prace'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-spaceship"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">PRACE <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'partner-slices'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-spaceship"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">SLICES <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                    </div>
                </base-dropdown>

                <!-- FOR USERS MENU -->
                <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="ni ni-chat-round d-lg-none"></i>
                        <span class="nav-link-inner--text text-sc header-menu-button">For Users</span>
                    </a>
                    <div class="dropdown-menu-inner">
                        <router-link :to="{ name: 'users-aup'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-bullet-list-67"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Acceptable Use Policy (AUP)</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'users-getting-started'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-button-play"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Getting started</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'users-activity-report'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-chart-line"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Activity Report 2020</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'users-guide'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-bookmark"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">User Guide</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'users-documentation'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-book"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Technical Documentation <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'users-training-assets'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-feather-alt"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Training Assets</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'users-data-processing'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-user-secret"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Personal Data Processing</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'users-services'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-wrench"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Complementary services</h5>
                            </div>
                        </router-link>
                    </div>
                </base-dropdown>

                <!-- EDUCATION MENU -->
                <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="fa fa-bullseye d-lg-none"></i>
                        <span class="nav-link-inner--text text-sc header-menu-button">Education</span>
                    </a>
                    <div class="dropdown-menu-inner">
                        <router-link :to="{ name: 'education-hpcschool'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-calendar"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">HPC School</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'education-eumaster4hpc'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white" style="padding-right: 3px; padding-left: 2px;">
                                <img class="fas" src="/img/icons/eumaster4hpc.svg" />
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">EUMaster4HPC <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'education-doctoral'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-graduation-cap"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Doctoral program <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'education-ul-master-hpc'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-chalkboard-teacher"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Master - HPC <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'education-mics'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-chalkboard-teacher"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Master - MICS <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'education-ulcourses'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-school"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">UL Course List <i class="fas fa-external-link-alt fa-xs"></i></h5>
                            </div>
                        </router-link>
                    </div>
                </base-dropdown>

                <!-- DEMONSTRATOR ENTRY -->
                <router-link :to="{ name: 'demonstrators'}" class="nav-item nav-link" role="button">
                    <i class="fas fa-photo-video d-lg-none"></i>
                    <span class="nav-link-inner--text text-sc header-menu-button">Demonstrators</span>
                </router-link>

                <!-- EVENT MENU -->
                <!--<base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="fas fa-calendar-alt d-lg-none"></i>
                        <span class="nav-link-inner--text">Events</span>
                    </a>
                    <div class="dropdown-menu-inner">
                    </div>
                </base-dropdown>-->

                <!-- ABOUT MENU -->
                <base-dropdown class="nav-item" menu-classes="dropdown-menu-xl">
                    <a slot="title" href="#" class="nav-link" data-toggle="dropdown" role="button">
                        <i class="fa fa-hashtag d-lg-none"></i>
                        <span class="nav-link-inner--text text-sc header-menu-button">About</span>
                    </a>
                    <div class="dropdown-menu-inner">
                        <router-link :to="{ name: 'blog'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-newspaper-o"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Blog posts</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'about-supercomputers'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-spaceship"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">What is HPC?</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'about-team'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-users"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">ULHPC Team</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'about-support'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fas fa-mail-bulk"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">ULHPC Support</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'about-communication'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-share-alt"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Logos & Communication materials</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'about-publications'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="ni ni-paper-diploma"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Publications</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'about-credits'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-lightbulb-o"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Credits</h5>
                            </div>
                        </router-link>
                        <router-link :to="{ name: 'legacy-website'}" class="media d-flex align-items-center">
                            <div class="icon icon-shape bg-gradient-primary rounded-circle text-white">
                                <i class="fa fa-archive"></i>
                            </div>
                            <div class="media-body ml-3">
                                <h5 class="heading text-dark mb-md-1">Old Website</h5>
                            </div>
                        </router-link>
                    </div>
                    
                </base-dropdown>
            </ul>

            <ul id="getting-started-header" class="navbar-nav align-items-lg-center ml-lg-auto">
                <li class="nav-item">
                    <a class="nav-link nav-link-icon" href="https://twitter.com/ULHPC" target="_blank" rel="noopener"
                       data-toggle="tooltip" title="Follow us on Twitter">
                        <i class="fa fa-twitter-square" style="font-size: 1.5rem"></i>
                        <span class="nav-link-inner--text d-lg-none">Twitter</span>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link nav-link-icon" href="https://github.com/ULHPC"
                       target="_blank" rel="noopener" data-toggle="tooltip" title="Follow us on Github">
                        <i class="fa fa-github" style="font-size: 1.5rem"></i>
                        <span class="nav-link-inner--text d-lg-none">Github</span>
                    </a>
                </li>
                <li class="nav-item d-none d-xl-block ml-lg-4">
                    <router-link :to="{ name: 'users-getting-started'}" class="btn btn-neutral btn-icon">
                        <span class="btn-inner--icon">
                            <i class="ni ni-button-play mr-2"></i>
                        </span>
                        <span class="nav-link-inner--text">Getting started</span>
                    </router-link>
                </li>
            </ul>
        </base-nav>
    </header>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";

export default {
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown
  }
};
</script>

<style>
.header-menu-button {
    font-size: 1.15rem;
}
</style>
