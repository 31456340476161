import Vue from "vue";
import Router from "vue-router";
import AppHeader from "./layout/AppHeader";
import AppFooter from "./layout/AppFooter";

const Homepage = () => import("./views/Homepage.vue");

const Datacenter = () => import(/* webpackChunkName: "group-mkcontent" */ './views/infrastructure/Datacenter.vue');
const Supercomputers = () => import(/* webpackChunkName: "group-mkcontent" */ './views/infrastructure/Supercomputers.vue');
const Storage = () => import(/* webpackChunkName: "group-mkcontent" */ './views/infrastructure/Storage.vue');
const Network = () => import(/* webpackChunkName: "group-mkcontent" */ './views/infrastructure/Network.vue');
const DevopsTech = () => import(/* webpackChunkName: "group-mkcontent" */ './views/infrastructure/DevopsTech.vue');
const Status = () => import(/* webpackChunkName: "group-mkcontent" */ './views/infrastructure/Status.vue');

const AUP = () => import(/* webpackChunkName: "group-mkcontent" */ './views/users/AUP.vue');
const GettingStarted = () => import(/* webpackChunkName: "group-mkcontent" */ './views/users/GettingStarted.vue');
const ActivityReport = () => import(/* webpackChunkName: "group-mkcontent" */ './views/users/ActivityReport.vue');
const UserGuide = () => import(/* webpackChunkName: "group-mkcontent" */ './views/users/UserGuide.vue');
const TrainingAssets = () => import(/* webpackChunkName: "group-mkcontent" */ './views/users/TrainingAssets.vue');
const DataProcessing = () => import(/* webpackChunkName: "group-mkcontent" */ './views/users/DataProcessing.vue');
const Services = () => import(/* webpackChunkName: "group-mkcontent" */ './views/users/Services.vue');

const Blog = () => import(/* webpackChunkName: "group-mkcontent" */ './views/Blog.vue');

const HPCSchool = () => import(/* webpackChunkName: "group-mkcontent" */ './views/education/HPCSchool.vue');

const Demonstrators = () => import(/* webpackChunkName: "group-mkcontent" */ './views/research/Demonstrators.vue');

const AboutSupercomputers = () => import(/* webpackChunkName: "group-mkcontent" */ './views/about/Supercomputers.vue');
const Team = () => import(/* webpackChunkName: "group-mkcontent" */ './views/about/Team.vue');
const Support = () => import(/* webpackChunkName: "group-mkcontent" */ './views/about/Support.vue');
const Communication = () => import(/* webpackChunkName: "group-mkcontent" */ './views/about/Communication.vue');
const Publications = () => import(/* webpackChunkName: "group-mkcontent" */ './views/about/Publications.vue');
const Credits = () => import(/* webpackChunkName: "group-mkcontent" */ './views/about/Credits.vue');

const SITE_BASE_URL = "https://hpc.uni.lu";
const LEGACY_SITE_BASE_URL = "https://hpc.uni.lu/old";
const TECHDOC_SITE_BASE_URL = "https://hpc-docs.uni.lu";

Vue.use(Router);

export default new Router({
  linkExactActiveClass: "active",
  mode: 'history',
  routes: [
    {
      path: "/",
      name: "homepage",
      components: {
        header: AppHeader,
        default: Homepage,
        footer: AppFooter
      }
    },

    // Infrastructure

    {
      path: "/infrastructure/datacenter",
      name: "infra-datacenter",
      components: {
        header: AppHeader,
        default: Datacenter,
        footer: AppFooter
      }
    },
    {
      path: "/infrastructure/supercomputers",
      name: "infra-supercomputers",
      components: {
        header: AppHeader,
        default: Supercomputers,
        footer: AppFooter
      }
    },
    {
      path: "/infrastructure/storage",
      name: "infra-storage",
      components: {
        header: AppHeader,
        default: Storage,
        footer: AppFooter
      }
    },
    {
      path: "/infrastructure/network",
      name: "infra-network",
      components: {
        header: AppHeader,
        default: Network,
        footer: AppFooter
      }
    },
    {
      path: "/infrastructure/devops-technologies",
      name: "infra-devops",
      components: {
        header: AppHeader,
        default: DevopsTech,
        footer: AppFooter
      }
    },
    {
      path: "/live-status/motd",
      name: "infra-status",
      components: {
        header: AppHeader,
        default: Status,
        footer: AppFooter
      }
    },

    // Research

    /*{
      path: "/research/demonstrators",
      name: "reseach-demos",
      components: {
        header: AppHeader,
        default: Demonstrators,
        footer: AppFooter
      },
      children: [
        {
          path: "biomass",
          name: "biomass",
          props: {
            content: "blabla"//() => import('!raw-loader!@/contents/research/demonstrators/Biomass.md')
          }
        }
      ]
    },*/
    {
      path: "/research/demonstrators",
      name: "research-demos",
      components: {
        header: AppHeader,
        default: Demonstrators,
        footer: AppFooter
      },
    },
    {
      path: "/research/pcog",
      name: "research-pcog",
      beforeEnter() {
        window.open('https://pcog.uni.lu/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/research/luxdem",
      name: "research-luxdem",
      beforeEnter() {
        window.open('https://luxdem.uni.lu/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/research/tcplu",
      name: "research-tcplu",
      beforeEnter() {
        window.open('https://www.tcpunilu.com/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/research/lcsb",
      name: "research-lcsb",
      beforeEnter() {
        window.open('https://www.uni.lu/lcsb-en/research/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/research/snt",
      name: "research-snt",
      beforeEnter() {
        window.open('https://wwwen.uni.lu/snt/research', '_blank')
        return '/redirecting'
      }
    },

    // Partnership

    {
      path: "/partner/eurocc",
      name: "partner-eurocc",
      beforeEnter() {
        window.open('https://www.eurocc-access.eu', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/partner/grid5000",
      name: "partner-grid5000",
      beforeEnter() {
        window.open('https://www.grid5000.fr/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/partner/castiel",
      name: "partner-castiel",
      beforeEnter() {
        window.open('https://www.eurocc-access.eu/about-us/the-projects', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/partner/prace",
      name: "partner-prace",
      beforeEnter() {
        window.open('https://prace-ri.eu/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/partner/slices",
      name: "partner-slices",
      beforeEnter() {
        window.open('https://slices-ri.eu/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/partner/etp4hpc",
      name: "partner-etp4hpc",
      beforeEnter() {
        window.open('https://www.etp4hpc.eu/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/partner/eurohpc",
      name: "partner-eurohpc",
      beforeEnter() {
        window.open('https://eurohpc-ju.europa.eu/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/partner/luxprovide",
      name: "partner-luxprovide",
      beforeEnter() {
        window.open('https://luxprovide.lu/', '_blank')
        return '/redirecting'
      }
    },

    // Users

    {
      path: "/users/aup",
      name: "users-aup",
      components: {
        header: AppHeader,
        default: AUP,
        footer: AppFooter
      }
    },
    {
      path: "/users/getting-started",
      name: "users-getting-started",
      components: {
        header: AppHeader,
        default: GettingStarted,
        footer: AppFooter
      }
    },
    {
      path: "/users/activity-report",
      name: "users-activity-report",
      components: {
        header: AppHeader,
        default: ActivityReport,
        footer: AppFooter
      }
    },
    {
      path: "/users/guide",
      name: "users-guide",
      components: {
        header: AppHeader,
        default: UserGuide,
        footer: AppFooter
      }
    },
    {
      path: "/users/documentation",
      name: "users-documentation",
      beforeEnter() {
        window.open(TECHDOC_SITE_BASE_URL + '/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/users/training-assets",
      name: "users-training-assets",
      components: {
        header: AppHeader,
        default: TrainingAssets,
        footer: AppFooter
      }
    },
    {
      path: "/users/data-processing",
      name: "users-data-processing",
      components: {
        header: AppHeader,
        default: DataProcessing,
        footer: AppFooter
      }
    },
    {
      path: "/users/services",
      name: "users-services",
      components: {
        header: AppHeader,
        default: Services,
        footer: AppFooter
      }
    },

    // Education

    {
      path: "/education/hpcschool/:edition?",
      name: "education-hpcschool",
      components: {
        header: AppHeader,
        default: HPCSchool,
        footer: AppFooter
      },
      props: {
        header: false,
        default: true,
        footer: false
      }
    },
    {
      path: "/education/doctoral",
      name: "education-doctoral",
      beforeEnter() {
        window.open('https://www.uni.lu/research-en/doctoral-education/dsse/computer-engineering/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/education/eumaster4hpc",
      name: "education-eumaster4hpc",
      beforeEnter() {
        window.open('https://eumaster4hpc.uni.lu/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/education/ul-master-hpc",
      name: "education-ul-master-hpc",
      beforeEnter() {
        window.open('https://www.uni.lu/fstm-en/study-programs/master-in-high-performance-computing/', '_blank')
        return '/redirecting'
      }
    },
    {
      path: "/education/mics",
      name: "education-mics",
      beforeEnter() {
        window.open('https://www.uni.lu/fstm-en/study-programs/master-in-information-and-computer-sciences/', '_blank')
        return '/redirecting'
      }
    },

    {
      path: "/education/ulcourses",
      name: "education-ulcourses",
      beforeEnter() {
        window.open('https://www.uni.lu/en/education/study-programme-overview/', '_blank')
        return '/redirecting'
      }
    },


    // BLOG

    {
      path: "/blog/:slug?",
      name: "blog",
      components: {
        header: AppHeader,
        default: Blog,
        footer: AppFooter
      },
      props: {
        header: false,
        default: true,
        footer: false
      }
    },

    // Demonstrators
    {
      path: "/demonstrators",
      name: "demonstrators",
      beforeEnter() {
        window.open('https://hpc-demos.uni.lu/', '_blank')
        return '/redirecting'
      }
    },
    
    // About

    {
      path: "/about/supercomputers",
      name: "about-supercomputers",
      components: {
        header: AppHeader,
        default: AboutSupercomputers,
        footer: AppFooter
      }
    },
    {
      path: "/about/team",
      name: "about-team",
      components: {
        header: AppHeader,
        default: Team,
        footer: AppFooter
      }
    },
    {
      path: "/about/support",
      name: "about-support",
      components: {
        header: AppHeader,
        default: Support,
        footer: AppFooter
      }
    },
    {
      path: "/about/communication",
      name: "about-communication",
      components: {
        header: AppHeader,
        default: Communication,
        footer: AppFooter
      }
    },
    {
      path: "/about/publications",
      name: "about-publications",
      components: {
        header: AppHeader,
        default: Publications,
        footer: AppFooter
      }
    },
    {
      path: "/about/credits",
      name: "about-credits",
      components: {
        header: AppHeader,
        default: Credits,
        footer: AppFooter
      }
    },
    {
      path: "/legacy",
      name: "legacy-website",
      beforeEnter() {
        window.open(LEGACY_SITE_BASE_URL, '_blank')
        return '/redirecting'
      }
    },

    // If 404 (it means we do not find the URI)
    {
      path: '*',
      beforeEnter() {
        if (/^\/old/.test(location.pathname)){
          location.href = SITE_BASE_URL;
        } else {
          location.href = LEGACY_SITE_BASE_URL + location.pathname + location.search + location.hash;
        }
        return '/redirecting'
      }
    }
  ],
  scrollBehavior: to => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  }
});
