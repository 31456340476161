<template>
  <div class="card">
    <div class="bg-white rounded shadow-sm py-5 px-4">
      <div>
        <a :href="website" target="_blank">
          <img
            :src="imgUrl"
            alt=""
            width="150"
            class="img-fluid mb-3"
          />
        </a>
        <h5 class="mb-0"><a :href="website" target="_blank">{{ name }} <i class="fas fa-external-link-alt" style="font-size: 0.5em"></i></a></h5>
        <span v-if="description" class="small text-uppercase text-muted mb-0">{{ description }}</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "tech-card",
  props: {
    imgUrl: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    description: {
      type: String,
      default: "",
    },
    website: {
      type: String,
      default: "",
    },
  },
};
</script>
<style>
</style>
