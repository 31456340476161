import MarkdownItVue from 'markdown-it-vue/dist/markdown-it-vue.umd';
import Badge from "../components/Badge";
import BaseAlert from "../components/BaseAlert";
import BaseButton from "../components/BaseButton";
import BaseCheckbox from "../components/BaseCheckbox";
import BaseInput from "../components/BaseInput";
import BasePagination from "../components/BasePagination";
import BaseProgress from "../components/BaseProgress";
import BaseRadio from "../components/BaseRadio";
import BaseSlider from "../components/BaseSlider";
import BaseSwitch from "../components/BaseSwitch";
import Card from "../components/Card";
import StaffCard from "../components/StaffCard";
import TechCard from "../components/TechCard";
import Icon from "../components/Icon";
import SkewedTop from "../components/SkewedTop";
import MarkdownPage from "../components/MarkdownPage";
import StatusTable from "../components/StatusTable";
import StatusIndicator from '../components/StatusIndicator/StatusIndicator.vue';

export default {
  install(Vue) {
    Vue.component(MarkdownItVue.name, MarkdownItVue);
    Vue.component(Badge.name, Badge);
    Vue.component(BaseAlert.name, BaseAlert);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(BaseProgress.name, BaseProgress);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(BaseSlider.name, BaseSlider);
    Vue.component(BaseSwitch.name, BaseSwitch);
    Vue.component(Card.name, Card);
    Vue.component(StaffCard.name, StaffCard);
    Vue.component(TechCard.name, TechCard);
    Vue.component(Icon.name, Icon);
    Vue.component(SkewedTop.name, SkewedTop);
    Vue.component(MarkdownPage.name, MarkdownPage);
    Vue.component(StatusTable.name, StatusTable);
    Vue.component(StatusIndicator.name, StatusIndicator);
  }
};
