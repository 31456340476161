<template>
    <div class="position-relative">
        <section class="section-shaped my-0">
            <div class="shape shape-style-3 shape-default shape-skew" id="skewedtop"></div>
            <div class="container shape-container-header d-flex"></div>
        </section>
    </div>
</template>

<script>
export default {
    name: "skewed-top"
};
</script>

<style>
#skewedtop::after {
  content: "";
  background: url('/assets/img/common/hero_fili_retouched.jpg') no-repeat;
  background-position: 50% 60%;
  background-size: cover;
  opacity: 0.5;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  filter: blur(4px);
  -webkit-filter: blur(4px);
}
</style>
